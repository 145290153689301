export default {
  data() {
    return {
      lang: localStorage.getItem('ZHJD_lang') || 'zh',
      // langImg: require('@/assets/lang/zh.png')
    }
  },
  computed: {
    langImg() {
      const l = this.lang
      let img = ''
      if (l === 'zh') {
        img = require('@/assets/lang/zh.png')
      } else {
        img = require('@/assets/lang/ug.png')
      }

      return img
    },
  },
  methods: {
    langChange() {
      let l = this.lang
      if (l === 'zh') {
        l = 'ug'
      } else {
        l = 'zh'
      }
      this.lang = l
      localStorage.setItem('ZHJD_lang', l)
      this.$i18n.locale = l
      this.langImg = require(`@/assets/lang/${l}.png`)
    },
  },
}