<template>
  <div class="head-box">
    <img :src="langImg" class="lang-change" @click="langChange" />
    <img src="../../assets/tab-pic.jpg" class="banner" />
  </div>
</template>

<script>
  import LangMixin from '../../mixins/LangMixin'
  export default {
    name: 'HeadBox',
    mixins: [LangMixin],
  }
</script>

<style scoped>
.banner {
  width: 100%;
  height: auto;
}
.head-box {
  position: relative;
}
.lang-change {
  position: absolute;
  right: 1rem;
  top: .5rem;
  width: 4rem;
  /* border-radius: 50%;
  box-shadow: 0px 0px 20px #fff; */
}
</style>